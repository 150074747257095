
export default [
    {
        path: "/",
        name: "default",
        meta: {
            title: "Dashboard",
            authRequired: true,
            key: "dashboard"
        },
        component: () => import("../views/dashboard/index.vue"),

    },
    {
        path: "/redevables",
        name: "redevables",
        meta: {
            title: "Assujetis",
            authRequired: true,
            key: "dashboard"
        },
        component: () => import("../views/redevables/liste.vue"),

    },
    {
        path: "/transactions",
        name: "transactions",
        meta: {
            title: "Transactions",
            authRequired: true,
            key: "dashboard"
        },
        component: () => import("../views/transactions/liste.vue"),

    },
    {
        path: "/login",
        name: "login",
        component: () => import("../views/auth/login.vue"),
        meta: {
            title: "Login",
        },
    },
    {
        path: "/configurations",
        name: "configurations",
        meta: {
            title: "Configurations",
            authRequired: true,
            key: "configurations"
        },
        children:[
            {
                path: "redevances",
                name: "configRedevances",
                meta: {
                    title: "Redevances",
                    authRequired: true,
                    key: "config-redevances"
                },
                children:[
                    {
                        path: "",
                        name: "configListeRedevances",
                        meta: {
                            title: "Redevances",
                            authRequired: true,
                            key: "config-list-redevances"
                        },
                        component: () => import("../views/configurations/redevances/index.vue"),
                    },
                    {
                        path: "create",
                        name: "configCreateRedevances",
                        meta: {
                            title: "Redevances",
                            authRequired: true,
                            key: "config-create-redevances"
                        },
                        component: () => import("../views/configurations/redevances/create.vue"),
                    },
                    {
                        path: ":id/applications",
                        name: "configRedevanceApplications",
                        meta: {
                            title: "Redevances",
                            authRequired: true,
                            key: "config-list-redevances"
                        },
                        children:[
                            {
                                path: "",
                                name: "configListRedevanceApplications",
                                meta: {
                                    title: "Redevances",
                                    authRequired: true,
                                    key: "config-list-redevances"
                                },
                                component: () => import("../views/configurations/redevances/applications/index.vue"),
                            },
                            {
                                path: ":appid/edit",
                                name: "configEditRedevanceApplications",
                                meta: {
                                    title: "Redevances",
                                    authRequired: true,
                                    key: "config-list-redevances"
                                },
                                component: () => import("../views/configurations/redevances/applications/edit.vue"),
                            },
                            {
                                path: "create",
                                name: "configCreateRedevanceApplications",
                                meta: {
                                    title: "Redevances",
                                    authRequired: true,
                                    key: "config-list-redevances"
                                },
                                component: () => import("../views/configurations/redevances/applications/create.vue"),
                            },
                        ],
                    },
                ]
            },
            {
                path: "secteurs",
                name: "configSecteurs",
                meta: {
                    title: "Secteurs",
                    authRequired: true,
                    key: "config-secteurs"
                },
                children:[
                    {
                        path: "",
                        name: "configListeSecteurs",
                        meta: {
                            title: "Redevances",
                            authRequired: true,
                            key: "config-list-secteurs"
                        },
                        component: () => import("../views/configurations/secteurs/index.vue"),
                    },
                    {
                        path: "create",
                        name: "configCreateSecteurs",
                        meta: {
                            title: "Redevances",
                            authRequired: true,
                            key: "config-create-secteurs"
                        },
                        component: () => import("../views/configurations/secteurs/create.vue"),
                    },
                    {
                        path: ":id/edit",
                        name: "configEditSecteurs",
                        meta: {
                            title: "Redevances",
                            authRequired: true,
                            key: "config-create-secteurs"
                        },
                        component: () => import("../views/configurations/secteurs/edit.vue"),
                    },
                    {
                        path: ":id/add",
                        name: "configAddSecteurs",
                        meta: {
                            title: "Redevances",
                            authRequired: true,
                            key: "config-add-secteurs"
                        },
                        component: () => import("../views/configurations/secteurs/add.vue"),
                    },
                ]
            },
            {
                path: "categorie-activite",
                name: "configCategorieActivites",
                meta: {
                    title: "Catégorie activités",
                    authRequired: true,
                    key: "config-categorie-activite"
                },
                children:[
                    {
                        path: "",
                        name: "configListeCategorieActivite",
                        meta: {
                            title: "Catégorie d'activité",
                            authRequired: true,
                            key: "config-list-categorie-activite"
                        },
                        component: () => import("../views/configurations/activite/index.vue"),
                    },
                    {
                        path: "create",
                        name: "configCreateCategorieActivite",
                        meta: {
                            title: "Catégorie d'activité",
                            authRequired: true,
                            key: "config-create-categorie-activite"
                        },
                        component: () => import("../views/configurations/activite/create.vue"),
                    },
                    {
                        path: ":id/edit",
                        name: "configEditCategorieActivite",
                        meta: {
                            title: "Redevances",
                            authRequired: true,
                            key: "config-create-categorie-activite"
                        },
                        component: () => import("../views/configurations/activite/edit.vue"),
                    },
                ]
            },
            {
                path: "roles",
                name: "configRoles",
                meta: {
                    title: "Roles",
                    authRequired: true,
                    key: "config-roles"
                },
                children:[
                    {
                        path: "",
                        name: "configListeRoles",
                        meta: {
                            title: "Roles",
                            authRequired: true,
                            key: "config-list-roles"
                        },
                        component: () => import("../views/configurations/roles/index.vue"),
                    },
                    {
                        path: "create",
                        name: "configCreateRoles",
                        meta: {
                            title: "Catégorie d'activité",
                            authRequired: true,
                            key: "config-create-roles"
                        },
                        component: () => import("../views/configurations/roles/create.vue"),
                    },
                    {
                        path: ":id/edit",
                        name: "configEditRoles",
                        meta: {
                            title: "Roles",
                            authRequired: true,
                            key: "config-create-roles"
                        },
                        component: () => import("../views/configurations/roles/edit.vue"),
                    },
                ]
            },
            {
                path: "users",
                name: "configUsers",
                meta: {
                    title: "Users",
                    authRequired: true,
                    key: "config-users"
                },
                children:[
                    {
                        path: "",
                        name: "configListeUsers",
                        meta: {
                            title: "Roles",
                            authRequired: true,
                            key: "config-list-users"
                        },
                        component: () => import("../views/configurations/users/index.vue"),
                    },
                    {
                        path: "create",
                        name: "configCreateUsers",
                        meta: {
                            title: "Catégorie d'activité",
                            authRequired: true,
                            key: "config-create-roles"
                        },
                        component: () => import("../views/configurations/users/create.vue"),
                    },
                    {
                        path: ":id/edit",
                        name: "configEditUsers",
                        meta: {
                            title: "Roles",
                            authRequired: true,
                            key: "config-edit-users"
                        },
                        component: () => import("../views/configurations/users/edit.vue"),
                    },
                ]
            }
        ]

    },

];