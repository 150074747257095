<template>
     <div class="container-fluid">        
            <div class="page-title">
              <div class="row">
                <div class=" col-sm-6">
                  <h3>{{ title }}</h3>
                </div>
                <div class=" col-sm-6">
                    <ol class="breadcrumb m-0">
                        <li class="breadcrumb-item" v-for="(item, index) in items" :key="index" :class="{ active: item[0] }">
                            <b-link :href="item.href">{{ item.text }}</b-link>
                        </li>
                    </ol>
                </div>
              </div>
            </div>
     </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            default: "",
        },
        items: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
}
</script>