import { createStore } from 'vuex'
import menu from "./modules/menu"
import layout from "./modules/layout"
import language from "./modules/language"

export default createStore({
  state: {
    currentUser: JSON.parse(localStorage.getItem('currentUser')),
    daterange: localStorage.getItem('daterange'),
    selectedAnnee: localStorage.getItem('selectedAnnee'),
    selectedRamification: JSON.parse(localStorage.getItem('selectedRamification')),

  },
  getters: {
  },
  mutations: {
    SET_SELECTED_RAMIFICATION(state, newValue) {
      state.selectedRamification = newValue
      saveState('setSelectedRamification', newValue)
    },
    SET_SELECTED_ANNEE(state, newValue) {
      state.selectedAnnee = newValue
      saveState('setAnnee', newValue)

    },
    SET_DATERANGE(state, newValue) {
      state.daterange = newValue
      saveState('setDaterange', newValue)
    },

    SET_CURRENT_USER(state, newValue) {
      state.currentUser = newValue
      saveState('currentUser', newValue)
    },
  },
  actions: {
    setSelectedRamification({ commit }, ramification) {
      commit('SET_SELECTED_RAMIFICATION', ramification)
    },
    setAnnee({ commit }, annee) {
      commit('SET_SELECTED_ANNEE', annee)
    },
    setDaterange({ commit }, daterange) {
      commit('SET_DATERANGE', daterange)
    },
    logIn({ commit }, user= {}) {
      commit('SET_CURRENT_USER', user)
    },
  },
  modules: {
    menu,
    layout,
    language
  }
})

function saveState(key, state) {
  window.sessionStorage.setItem(key, JSON.stringify(state))
}
